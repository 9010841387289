import { last, get } from 'lodash';
import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { getFullImageUrl, isDamSrc, getYoutubePreviewImage, YOUTUBE_PREVIEW_SIZE } from 'client/utils/image-helpers';

function getThumbUrl(url) {
  return isDamSrc(url) ? getFullImageUrl(url) : url;
}

export function getVideoSchema(videoObject, { noTranscript } = {}) {
  const {
    videoDescription,
    videoTitle,
    uploadDate,
    thumbnailURL,
    videoTranscript,
    videoId,
    'youtube-videoid': youtubeVideoId,
    googleThumbnail,
  } = videoObject;
  const transcript = noTranscript ? undefined : videoTranscript;
  const description = videoDescription || transcript;

  // Return null if we can't fill in required fields
  if (!videoTitle || !thumbnailURL || !uploadDate) return null;

  return {
    '@context': SCHEMA_URL,
    '@type': 'VideoObject',
    description,
    name: videoTitle,
    transcript,
    thumbnailUrl: googleThumbnail ? getThumbUrl(googleThumbnail) : getThumbUrl(thumbnailURL),
    contentUrl: `https://www.youtube.com/watch?v=${videoId || youtubeVideoId}`,
    uploadDate,
  };
}

export function getEditorialVideoSchema(video) {
  const { title, url, publishDate } = video;
  const videoId = last(url.split('/'));
  const thumbnailURL = getYoutubePreviewImage(videoId, YOUTUBE_PREVIEW_SIZE.HQ);

  return getVideoSchema({
    videoTitle: title,
    uploadDate: publishDate,
    videoId,
    thumbnailURL,
    videoDescription: title,
  });
}

export function getItemListVideoSchema(videos, { noTranscript } = {}) {
  return {
    '@context': SCHEMA_URL,
    '@type': 'ItemList',
    itemListElement: videos.map((video, index) => {
      const videoSchema = getVideoSchema(video, { noTranscript });

      return { ...videoSchema, position: index + 1, url: get(videoSchema, 'contentUrl') };
    }),
  };
}
