import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import {
  ConsumerReviewsModel,
  ConsumerReviewsAspects,
  ConsumerReviewsPaths,
} from 'client/data/models/consumer-reviews';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { FilterButton } from 'site-modules/shared/components/consumer-review-aspect-filter-buttons/filter-button';

import './consumer-review-aspect-filter-buttons.scss';

const POSITIVE_GROUPING = 'Pros';
const NEGATIVE_GROUPING = 'Cons';
const POSITIVE_POLARITY = 'POSITIVE';
const NEGATIVE_POLARITY = 'NEGATIVE';
const COLLAPSE_ASPECT_LIMIT = 6;

const createButtons = (aspects, active, setAspect, polarity, className) =>
  aspects.map(aspect => (
    <li className="d-inline" key={aspect.topic}>
      <FilterButton
        className={classnames(className, {
          selected: active && active.topic === aspect.topic,
        })}
        aspect={aspect}
        polarity={polarity}
        handleFilterChange={setAspect}
      />
    </li>
  ));

const proConButtonGrouping = (aspects, active, setAspect, sectionName, classes, buttonClassName) => {
  const polarity = sectionName === POSITIVE_GROUPING ? POSITIVE_POLARITY : NEGATIVE_POLARITY;
  return (
    <Col xs={12} md={6} className={classnames('text-gray-darker', classes)}>
      {sectionName}
      <hr className="mt-0_25 mb-1" />
      <ul className="pros-cons-filter-buttons list-unstyled m-0">
        {createButtons(aspects, active, setAspect, polarity, buttonClassName)}
      </ul>
    </Col>
  );
};

function ConsumerReviewAspectFilterButtonsUI({
  consumerReviewsAspects,
  active,
  headerText,
  headerClassName,
  buttonClassName,
  setAspect,
  isProsConsGrouping,
  isMobile,
  heading: Heading,
}) {
  const aspectsLength =
    get(consumerReviewsAspects, 'pros.length', 0) +
    get(consumerReviewsAspects, 'cons.length', 0) +
    get(consumerReviewsAspects, 'neutral.length', 0);

  if (!aspectsLength) {
    return null;
  }

  return (
    <section className="consumer-review-aspect-filter-buttons mt-2">
      {isProsConsGrouping ? (
        <Fragment>
          <header>
            <Heading className="mb-1 heading-4">{headerText}</Heading>
          </header>
          <Row>
            {proConButtonGrouping(
              consumerReviewsAspects.pros,
              active,
              setAspect,
              POSITIVE_GROUPING,
              null,
              buttonClassName
            )}
            {proConButtonGrouping(
              consumerReviewsAspects.cons,
              active,
              setAspect,
              NEGATIVE_GROUPING,
              'mt-0_5 mt-md-0',
              buttonClassName
            )}
          </Row>
        </Fragment>
      ) : (
        <Fragment>
          <header>
            <Heading className={headerClassName}>{headerText}</Heading>
          </header>
          {!isMobile || aspectsLength > COLLAPSE_ASPECT_LIMIT ? (
            <ContentCollapse
              minHeight="96px" // 2 rows x 32px(button height) + 2 rows x 16px(padding height)
              textClosed="More"
              textOpen="Less"
              collapseClassName="overflow-hidden-focusable"
              justify="justify-content-start"
              btnTextClasses="fw-normal"
              btnClasses="px-0_5 pt-0 size-16 text-primary-darker text-capitalize"
              btnContainerClasses={classnames({ 'mt-0_5': isMobile, 'd-none': !isMobile })}
              btnArrowClasses="small"
              showBtnBelow
              isEclipseFade={false}
              btnTrackingId="consumer_review_aspect"
            >
              <ul className="aspect-filter-buttons list-unstyled m-0">
                {createButtons(
                  [...consumerReviewsAspects.pros, ...consumerReviewsAspects.cons, ...consumerReviewsAspects.neutral],
                  active,
                  setAspect,
                  null,
                  buttonClassName
                )}
              </ul>
            </ContentCollapse>
          ) : (
            <ul className="aspect-filter-buttons p-0_25 list-unstyled m-0">
              {createButtons(
                [...consumerReviewsAspects.pros, ...consumerReviewsAspects.cons, ...consumerReviewsAspects.neutral],
                active,
                setAspect,
                null,
                buttonClassName
              )}
            </ul>
          )}
        </Fragment>
      )}
    </section>
  );
}

ConsumerReviewAspectFilterButtonsUI.propTypes = {
  setAspect: PropTypes.func.isRequired,
  consumerReviewsAspects: PropTypes.shape({
    pros: PropTypes.arrayOf(ConsumerReviewsAspects),
    cons: PropTypes.arrayOf(ConsumerReviewsAspects),
    neutral: PropTypes.arrayOf(ConsumerReviewsAspects),
  }),
  active: ConsumerReviewsAspects,
  headerText: PropTypes.string,
  headerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  isProsConsGrouping: PropTypes.bool,
  isMobile: PropTypes.bool,
  heading: PropTypes.node,
};

ConsumerReviewAspectFilterButtonsUI.defaultProps = {
  consumerReviewsAspects: null,
  isMobile: true,
  active: null,
  headerText: 'Trending topics in reviews',
  headerClassName: 'mb-1 heading-5',
  buttonClassName: null,
  isProsConsGrouping: false,
  heading: 'h2',
};

export const ConsumerReviewAspectFilterButtons = connectToModel(ConsumerReviewAspectFilterButtonsUI, {
  consumerReviewsAspects: bindToPath(ConsumerReviewsPaths.buildConsumerReviewsAspectsPath(), ConsumerReviewsModel),
});
