import { getMakeModelVsMakeModelUrl } from 'site-modules/shared/utils/core-link-constructor';

export const ALLOWED_MM_VS_MM_LINKS = {
  HONDA_HRV_VS_HONDA_CRV: '/car-comparisons/honda-hr-v-vs-honda-cr-v/',
  TOYOTA_RAV4_VS_HONDA_CRV: '/car-comparisons/toyota-rav4-vs-honda-cr-v/',
  SUBARU_FORESTER_VS_SUBARU_OUTBACK: '/car-comparisons/subaru-forester-vs-subaru-outback/',
  CHEVROLET_CAMARO_VS_FORD_MUSTANG: '/car-comparisons/chevrolet-camaro-vs-ford-mustang/',
  TOYOTA_CAMRY_VS_HONDA_ACCORD: '/car-comparisons/toyota-camry-vs-honda-accord/',
  HONDA_CIVIC_VS_TOYOTA_COROLLA: '/car-comparisons/honda-civic-vs-toyota-corolla/',
  AUDI_A3_VS_AUDI_A4: '/car-comparisons/audi-a3-vs-audi-a4/',
  NISSAN_ROGUE_VS_HONDA_CRV: '/car-comparisons/nissan-rogue-vs-honda-cr-v/',
};

export const getValidMakeModelVsMakeModelLink = (vehicle1, vehicle2) => {
  const allowedLinks = Object.values(ALLOWED_MM_VS_MM_LINKS);
  const link1 = getMakeModelVsMakeModelUrl(vehicle1, vehicle2);

  if (allowedLinks.includes(link1)) return link1;

  const link2 = getMakeModelVsMakeModelUrl(vehicle2, vehicle1);

  return allowedLinks.includes(link2) ? link2 : null;
};
