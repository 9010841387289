import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildMakeModelDefaultYear, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { buildCoreBreadcrumbs } from 'site-modules/shared/utils/core-breadcrumbs-builder';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';

function CoreBreadcrumbsUI({ vehicle, defaultYear, className, breadcrumbsParams, creativeId }) {
  return (
    <Fragment>
      <Breadcrumbs
        className={className}
        data={buildCoreBreadcrumbs({
          makeModelSubmodelYear: vehicle,
          defaultYear,
          ...breadcrumbsParams,
        })}
        creativeId={creativeId}
      />
    </Fragment>
  );
}

CoreBreadcrumbsUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  defaultYear: PropTypes.number,
  className: PropTypes.string,
  breadcrumbsParams: PropTypes.shape({}),
  creativeId: PropTypes.string,
};

CoreBreadcrumbsUI.defaultProps = {
  defaultYear: null,
  className: null,
  breadcrumbsParams: null,
  creativeId: undefined,
};

export const CoreBreadcrumbs = connectToModel(CoreBreadcrumbsUI, {
  defaultYear: bindToPath(
    ({ vehicle }) => buildMakeModelDefaultYear({ make: vehicle.make.slug, model: vehicle.model.slug }),
    VehicleModel
  ),
});
