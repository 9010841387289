export const SAFETY_MAP_CONSTANTS = {
  RATING: 'rating',
  STRING: 'string',
  PERCENTAGE: 'percentage',
};

export const safetyMap = {
  nhtsaRatings: {
    frontalBarrierCrash: {
      name: 'Frontal Barrier Crash Rating',
      values: {
        overall: {
          name: 'Overall',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
        driver: {
          name: 'Driver',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
        passenger: {
          name: 'Passenger',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
      },
    },
    sideCrash: {
      name: 'Side Crash Rating',
      values: {
        overall: {
          name: 'Overall',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
      },
    },
    sideBarrierCrash: {
      name: 'Side Barrier Rating',
      values: {
        overall: {
          name: 'Overall',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
        driver: {
          name: 'Driver',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
        passenger: {
          name: 'Passenger',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
      },
    },
    sideBarrierAndPoleCrash: {
      name: 'Combined Side Barrier & Pole Ratings',
      values: {
        frontSeat: {
          name: 'Front Seat',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
        backSeat: {
          name: 'Back Seat',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
      },
    },
    rollover: {
      name: 'Rollover',
      values: {
        rollover: {
          name: 'Rollover',
          type: SAFETY_MAP_CONSTANTS.RATING,
        },
        dynamicTestResult: {
          name: 'Dynamic Test Result',
          type: SAFETY_MAP_CONSTANTS.STRING,
        },
        riskOfRolloverPercent: {
          name: 'Risk Of Rollover',
          type: SAFETY_MAP_CONSTANTS.PERCENTAGE,
        },
      },
    },
  },
  iihsRatings: {
    smallOverlapFrontDriver: 'Small Overlap Front Driver-Side Test',
    smallOverlapFrontPassenger: 'Small Overlap Front Passenger-Side Test',
    moderateOverlapFront: 'Moderate Overlap Front Test – Original',
    moderateOverlapFrontUpdatedTest: 'Moderate Overlap Front Test – Updated',
    sideImpact: 'Side Impact Test – Original',
    sideImpactUpdatedTest: 'Side Impact Test – Updated',
    roofStrength: 'Roof Strength Test',
    rearHeadProtectionAndHeadRestraint: 'Rear Crash Protection / Head Restraint',
  },
};
