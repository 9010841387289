import PropTypes from 'prop-types';
import { FAIR_DEAL, GOOD_DEAL, GREAT_DEAL, HIGH_DEAL } from 'site-modules/shared/components/deal/deal-constants';
import { Gradient } from 'site-modules/shared/components/price-checker/price-checker-promo-chart/overlays/gradient/gradient';

export const PRICE_CHECKER_EVENTS = {
  SELECT_DEALER: 'price_checker.select_dealer',
  LIST_VIEW: 'price_checker.list_view',
  SUBMIT: 'price_checker.submit',
};

export const PRICE_CHECKER_CROWDSOURCING_CREATIVE_ID = 'price-checker-crowdsourcing';

export const PRICE_CHECKER_CREATIVE_ID = 'price-checker';
export const NEW_CORE_LEAD_SUBMITTER = 'new_core_lead_submitter';
export const EDMUNDS_PRICE_CHECKER_LOGO_PATH = '/img/price-checker/price-checker-edmunds-logo-colored-trademark.svg';
export const EDMUNDS_PRICE_CHECKER_LOGO_PATH_V2 = '/img/price-checker/price-checker-edmunds-logo.svg';

export const LEAD_FORM_EXPLAINING_TEXT =
  'Use this form and you’ll get their best price via email[savingsValue=0?: - save up to {savingsValue}.]';
export const LEAD_FORM_HEADING = 'Dealers can’t post their lowest prices online';

export const MAKES_WITH_DEFAULT_MSRP = ['honda', 'acura'];

export const VEHICLE_SELECT_MODE_MAP = {
  MMY: 'mmy',
  VIN: 'vin',
};

export const VEHICLE_SELECT_MODES = [
  { key: VEHICLE_SELECT_MODE_MAP.MMY, label: 'Trim' },
  { key: VEHICLE_SELECT_MODE_MAP.VIN, label: 'VIN' },
];

export const DEAL_LABEL = {
  GREAT: 'Great',
  GOOD: 'Good',
  FAIR: 'Fair',
  HIGH: 'High',
  DEALER: 'Dealer',
};

export const DROPDOWN_ERRORS = {
  MAKE: 'Select a make',
  MODEL: 'Select a model',
  YEAR: 'Select a year',
};

export const PRICE_ERRORS = {
  OUT_OF_RANGE: {
    userMsrp: 'This MSRP price is outside of the expected range',
    userDealerPrice: 'This Dealer price quote is outside of the expected range',
  },
  EXCEED_MAXIMUM: {
    userMsrp: 'Exceeds maximum value',
    userDealerPrice: 'Exceeds maximum value',
  },
};

export const PRICE_THRESHOLD = 500000;

export const CHART_PROPS_REDESIGN = {
  COMMON: {
    yRate: 2.5,
    keyPointsIndex: { lowEnd: 5, highEnd: 14 },
    columnWidth: { desktop: 20, mobile: 16 },
    columnPadding: 1,
    strokeLength: { desktop: 8, mobile: 8 },
    customLabels: [
      { label: DEAL_LABEL.GREAT, position: 12.5 },
      { label: DEAL_LABEL.GOOD, position: 37.5 },
      { label: DEAL_LABEL.FAIR, position: 62.5 },
      { label: DEAL_LABEL.HIGH, position: 87.5 },
    ],
    yStartingPoint: 100,
    chartHeight: 155,
    overlayConfig: {
      isOverlapArrow: false,
      component: Gradient,
      // includes startPos, doesn't include endPos
      overlaysProps: [
        {
          startPos: 0,
          endPos: 5,
          opacity: 0.4,
          colors: [
            { offset: '0%', stopColor: '#1358bf', stopOpacity: '0' },
            { offset: '48%', stopColor: '#1358bf', stopOpacity: '0.23' },
            { offset: '100%', stopColor: '#1358bf', stopOpacity: '0.6' },
          ],
        },
        {
          startPos: 5,
          endPos: 10,
          opacity: 0.4,
          colors: [
            { offset: '0%', stopColor: '#008ea1', stopOpacity: '0' },
            { offset: '48%', stopColor: '#008ea1', stopOpacity: '0.23' },
            { offset: '100%', stopColor: '#008ea1', stopOpacity: '0.6' },
          ],
        },
        {
          startPos: 10,
          endPos: 15,
          opacity: 0.4,
          colors: [
            { offset: '0%', stopColor: '#1a854a', stopOpacity: '0' },
            { offset: '48%', stopColor: '#1a854a', stopOpacity: '0.23' },
            { offset: '100%', stopColor: '#1a854a', stopOpacity: '0.6' },
          ],
        },
        {
          startPos: 15,
          endPos: 21,
          opacity: 0.4,
          colors: [
            { offset: '0%', stopColor: '#eb6817', stopOpacity: '0' },
            { offset: '48%', stopColor: '#eb6817', stopOpacity: '0.23' },
            { offset: '100%', stopColor: '#eb6817', stopOpacity: '0.6' },
          ],
        },
      ],
    },
  },
  DATA_POINTS: {
    WITH_DATA: [2, 2, 6, 4, 16, 20, 13, 16, 24, 20, 9, 10, 7, 18, 20, 8, 2, 16, 5, 2],
    WITHOUT_DATA: [2, 3, 5, 8, 10, 13, 16, 19, 22, 24, 24, 22, 19, 16, 13, 10, 8, 5, 3, 2],
  },
};

export const CHART_PROPS_VDP = {
  COMMON: {
    pricingData: {},
    yRate: 2,
    keyPointsIndex: { lowEnd: 2, highEnd: 10, middleEnd: 6 },
    showMarketRange: false,
    secondaryRangeColor: '#f59442',
    greatRangeColor: '#007ee5',
    goodRangeColor: '#0098c2',
    fairRangeColor: '#00c2ba',
    columnWidth: { desktop: 27, mobile: 18 },
    columnPadding: 1,
    strokeLength: { desktop: 13, mobile: 8 },
    customLabels: [
      { label: DEAL_LABEL.GREAT, position: 4.5 },
      { label: DEAL_LABEL.GOOD, position: 20.5 },
      { label: DEAL_LABEL.FAIR, position: 45.5 },
      { label: DEAL_LABEL.HIGH, position: 75.5 },
    ],
    yStartingPoint: 100,
    chartHeight: 180,
    selectedPricePointRadius: 6,
    highlightPrimaryRange: false,
    labelVerticalShift: { desktop: 25, mobile: 15 },
  },
  DATA_POINTS: {
    WITHOUT_DATA: [2, 3, 5, 8, 10, 13, 16, 19, 22, 24, 22, 19, 16, 13, 10, 8, 5, 3, 2],
    WITH_DATA: [12, 16, 8, 12, 12, 20, 12, 16, 28, 24, 20, 28, 32, 25, 16, 8, 12, 20, 8],
  },
};

export const CHART_PROPS_LEAD_FORM = {
  COMMON: {
    pricingData: {},
    yRate: 2,
    keyPointsIndex: { lowEnd: 5, highEnd: 14, middleEnd: 10 },
    showMarketRange: false,
    secondaryRangeColor: '#e6e6e6',
    columnWidth: { desktop: 27, mobile: 16 },
    columnPadding: 1,
    strokeLength: { desktop: 8, mobile: 8 },
    customLabels: [
      { label: DEAL_LABEL.GREAT, position: 12.5 },
      { label: DEAL_LABEL.GOOD, position: 37.5 },
      { label: DEAL_LABEL.FAIR, position: 62.5 },
      { label: DEAL_LABEL.HIGH, position: 87.5 },
    ],
    yStartingPoint: 80,
    chartHeight: 130,
    isArrowPositionMark: true,
    isCurvedLine: true,
    highlightPrimaryRange: false,
    xAxisColor: '#ffffff',
  },
  DATA_POINTS: {
    WITH_DATA: [2, 2, 6, 4, 16, 20, 13, 16, 24, 20, 12, 9, 10, 7, 18, 20, 8, 2, 24, 2],
    WITHOUT_DATA: [2, 3, 5, 8, 10, 13, 16, 19, 22, 24, 22, 19, 16, 13, 10, 8, 5, 3, 2],
  },
  GRADIENT: {
    [GREAT_DEAL]: {
      startPos: 0,
      endPos: 5,
      colors: [
        {
          offset: '0%',
          stopColor: 'rgb(0, 126, 229)',
          stopOpacity: '0',
        },
        {
          offset: '100%',
          stopColor: 'rgb(0, 126, 229)',
          stopOpacity: '0.43',
        },
      ],
    },
    [GOOD_DEAL]: {
      startPos: 5,
      endPos: 10,
      colors: [
        {
          offset: '0%',
          stopColor: 'rgb(0, 201, 227)',
          stopOpacity: '0',
        },
        {
          offset: '48%',
          stopColor: 'rgb(0, 176, 210)',
          stopOpacity: '0.23',
        },
        {
          offset: '100%',
          stopColor: 'rgb(0, 152, 194)',
          stopOpacity: '0.6',
        },
      ],
    },
    [FAIR_DEAL]: {
      startPos: 10,
      endPos: 15,
      colors: [
        {
          offset: '0%',
          stopColor: 'rgb(0, 201, 227)',
          stopOpacity: '0',
        },
        {
          offset: '48%',
          stopColor: 'rgb(0, 176, 210)',
          stopOpacity: '0.23',
        },
        {
          offset: '100%',
          stopColor: 'rgb(0, 194, 186)',
          stopOpacity: '0.6',
        },
      ],
    },
    [HIGH_DEAL]: {
      startPos: 15,
      endPos: 20,
      colors: [
        {
          offset: '0%',
          stopColor: 'rgb(245, 148, 66)',
          stopOpacity: '0',
        },
        {
          offset: '50%',
          stopColor: 'rgb(245, 148, 66)',
          stopOpacity: '0.19',
        },
        {
          offset: '100%',
          stopColor: 'rgb(245, 148, 66)',
          stopOpacity: '0.5',
        },
      ],
    },
  },
};

export const SHARED_UTM_PARAMS = {
  utmSource: {
    param: 'utm_source',
    value: 'price_checker',
  },
  utmMedium: {
    param: 'utm_medium',
    value: 'link',
  },
  utmTargetId: {
    param: 'utm_targetid',
    value: '',
  },
  utmCampaign: {
    param: 'utm_campaign',
    value: 'copy_url',
  },
  utmAccount: {
    param: 'utm_account',
    value: 'edmunds_insider',
  },
};

export const SLIDER_SECTION_LENGTH = 15;

export const PriceCheckerEntities = {
  SharedDataEntity: PropTypes.shape({
    zipCode: PropTypes.string,
    maxFairPrice: PropTypes.number,
    maxGoodPrice: PropTypes.number,
    maxGreatPrice: PropTypes.number,
    userDealerPrice: PropTypes.number,
    userMsrp: PropTypes.number,
    mode: PropTypes.string,
    styleId: PropTypes.string,
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string,
    vin: PropTypes.string,
    [SHARED_UTM_PARAMS.utmSource.param]: PropTypes.string,
    [SHARED_UTM_PARAMS.utmMedium.param]: PropTypes.string,
    [SHARED_UTM_PARAMS.utmTargetId.param]: PropTypes.string,
    [SHARED_UTM_PARAMS.utmCampaign.param]: PropTypes.string,
    [SHARED_UTM_PARAMS.utmAccount.param]: PropTypes.string,
  }),
  CommunityDealEntity: PropTypes.shape({
    selectedDealer: PropTypes.shape({}),
    isDealRated: PropTypes.bool,
    closestDealers: PropTypes.arrayOf(PropTypes.shape({})),
    ratedDealType: PropTypes.string,
    chartPrices: PropTypes.shape({
      userDealerPrice: PropTypes.number,
      userMsrp: PropTypes.number,
    }),
    handlePriceErrors: PropTypes.func,
    isDealSubmitted: PropTypes.bool,
  }),
};

export const PRICE_CHECKER_NAME = 'price-checker';

export const PRICE_COLOR = {
  [GREAT_DEAL]: 'rgb(0, 126, 229)',
  [GOOD_DEAL]: 'rgb(0, 152, 194)',
  [FAIR_DEAL]: 'rgb(0, 163, 144)',
  [HIGH_DEAL]: 'rgb(255, 130, 24)',
};
