import { isEmpty } from 'lodash';

/**
 * Checks whether object or all child objects are empty
 * @param ratings
 * @return {boolean}
 */
export function isNhtsaRatingsEmpty(ratings) {
  return isEmpty(ratings) || Object.keys(ratings).every(key => isEmpty(ratings[key]));
}
