export const Styles = {
  ul: {
    paddingLeft: 0,
    listStyleType: 'none',
  },
  li: {
    marginBottom: '12px',
  },
  imgWrapper: {
    maxWidth: '300px',
    height: '183px',
    marginTop: '-4px',
  },
  coreImg: {
    maxWidth: '280px',
    maxHeight: '183px',
  },
  figcaption: {
    bottom: 0,
  },
};
