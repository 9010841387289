import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, compact, orderBy } from 'lodash';
import Col from 'reactstrap/lib/Col';
import Card from 'reactstrap/lib/Card';
import Button from 'reactstrap/lib/Button';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildPopularStylesPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { PageModel } from 'client/data/models/page';
import { VehicleDetailsModel, VehicleComparisonsEntities, buildComparisonsPath } from 'client/data/models/mmy-details';
import { TypeRankingsEntities } from 'client/data/models/type-rankings-entities';
import { buildSegmentRatingsPath, TypeRankingsFeaturesModel } from 'client/data/models/type-rankings-features';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getDefaultStockPhotoBySize, getMediaImageUrl } from 'client/utils/image-helpers';
import { getCoreUrl, getVehicleComparisonUrl } from 'site-modules/shared/utils/core-link-constructor';
import { getBestSegmentRating } from 'site-modules/shared/utils/core-page/rating-and-ranking';
import { getValidMakeModelVsMakeModelLink } from 'client/site-modules/shared/utils/vehicle-comparisons-utils';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { buildRankingsSubsegmentLink } from 'site-modules/shared/utils/upper-funnel/type-rankings';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';
import { Link } from 'site-modules/shared/components/link/link';
import { Checkbox } from 'site-modules/shared/components/checkbox/checkbox';

import './vehicle-comparisons.scss';

const DISPLAY_CARDS_NUM = 3;

export function VehicleComparisonsUI({ vehicleComparisons, segmentRating, styles, sharedTrimName }) {
  const currentStyle = (styles && styles.find(({ trim }) => trim === sharedTrimName)) || get(styles, '[0]');

  const [selectedStyles, setSelectedStyles] = useState(
    compact([
      get(segmentRating, 'baseStyleId') ||
        get(vehicleComparisons, 'comparisons[0].vehicles[0].style') ||
        get(currentStyle, 'id'),
    ])
  );
  const cardsRef = useRef();

  useEffect(() => {
    setSelectedStyles([
      get(segmentRating, 'baseStyleId') ||
        get(vehicleComparisons, 'comparisons[0].vehicles[0].style') ||
        get(currentStyle, 'id'),
    ]);
  }, [segmentRating, vehicleComparisons, currentStyle]);

  function onCheckboxChange() {
    setSelectedStyles(
      Array.from(cardsRef.current.querySelectorAll('.compare-checkbox input:checked')).map(({ value }) => value)
    );
  }

  if (!get(vehicleComparisons, 'comparisons') && !segmentRating && !styles) {
    return null;
  }

  const currentVehicleRank = get(segmentRating, 'rank');
  let comparedVehicles = [];
  if (currentVehicleRank) {
    const currentRankedVehicle = segmentRating.editorialSegment.segmentRatings.find(
      ({ rank }) => rank === currentVehicleRank
    );

    comparedVehicles = segmentRating.editorialSegment.segmentRatings.slice(0, DISPLAY_CARDS_NUM);
    comparedVehicles[Math.min(DISPLAY_CARDS_NUM - 1, currentVehicleRank - 1)] = currentRankedVehicle;
    comparedVehicles = comparedVehicles.map(({ baseStyleId, modelYear, rank }) => ({
      make: modelYear.makeName,
      model: modelYear.modelName,
      makeSlug: modelYear.makeSlug,
      modelSlug: modelYear.modelSlug,
      year: modelYear.year,
      style: baseStyleId,
      rank,
    }));
  }

  const comparisons = get(vehicleComparisons, 'comparisons', []);
  const textComparisons = comparisons.filter(({ content }) => content);
  const currentVehicle = get(comparisons, '[0].vehicles[0]') ||
    comparedVehicles[Math.min(DISPLAY_CARDS_NUM - 1, currentVehicleRank - 1)] || {
      ...currentStyle,
      make: currentStyle.makeName,
      model: currentStyle.modelName,
      style: currentStyle.id,
    };
  currentVehicle.isCurrentVehicle = true;
  if (!comparedVehicles.length && get(comparisons, 'length')) {
    comparedVehicles = [currentVehicle, ...comparisons.map(({ vehicles }) => vehicles[1])]
      .map(vehicle => ({
        ...vehicle,
        makeSlug: makeNiceName(vehicle.make),
        modelSlug: makeNiceName(vehicle.model),
      }))
      .slice(0, DISPLAY_CARDS_NUM);
  }

  const hasComparisons = !!comparedVehicles.length;
  comparedVehicles = hasComparisons ? comparedVehicles : [currentVehicle];

  return (
    <div className="vehicle-comparisons" data-tracking-parent="vs_the_competition_link">
      <h2 className="heading-3 text-start mb-1_5">
        Compare the {currentVehicle.make} {currentVehicle.model}
      </h2>
      <ul className="row list-unstyled" ref={cardsRef}>
        {comparedVehicles.map(({ make, model, makeSlug, modelSlug, year, style, rank, isCurrentVehicle }, index) => {
          const LinkTag = isCurrentVehicle ? 'span' : Link;
          const linkProps = isCurrentVehicle
            ? {}
            : { to: getCoreUrl({ makeSlug, modelSlug }), 'data-tracking-id': 'compare_core_link' };

          return (
            <Col tag="li" xs={6} md={3} key={`${makeSlug}-${modelSlug}`} className="mb-1_5">
              <Card
                className={classnames('overflow-hidden h-100', {
                  selected: currentVehicleRank ? rank === currentVehicleRank : !index,
                })}
              >
                <AspectRatioContainer aspectRatio="3:2" className="mb-0_5">
                  <img
                    src={getMediaImageUrl(
                      getDefaultStockPhotoBySize({ make: makeSlug, model: modelSlug, year }, 300, 200)
                    )}
                    alt=""
                  />
                </AspectRatioContainer>
                <div className="vehicle-name mb-0_5 px-0_75 text-center">
                  <LinkTag
                    {...linkProps}
                    id={`comparisons-name-${style}`}
                    className="text-primary-darker d-inline-block fw-bold size-16"
                  >
                    {make}&nbsp;{model}
                  </LinkTag>
                </div>
                {hasComparisons && (
                  <Fragment>
                    <hr className="mb-0 mt-auto w-100" />
                    <Checkbox
                      id={`comparison-${style}`}
                      labelText="Compare"
                      containerClassName="compare-checkbox d-flex align-items-center text-gray-darker medium rounded px-0_75 my-0_5"
                      customLabelClassName="rounded"
                      textLabelClassName="pl-1_5"
                      data-hidedelightedoninteraction
                      onChange={onCheckboxChange}
                      value={style}
                      aria-labelledby={classnames(`checkbox-comparison-${style}`, `comparisons-name-${style}`)}
                      defaultChecked={currentVehicleRank ? rank === currentVehicleRank : !index}
                      data-tracking-id="compare_checkbox"
                      data-tracking-value={`${make} ${model}`}
                    />
                    {!!rank && (
                      <div className="rank pos-a top-0 left-0 heading-4 bg-gray-extra-light px-0_5 py-0_25">{rank}</div>
                    )}
                  </Fragment>
                )}
              </Card>
            </Col>
          );
        })}
        <Col tag="li" xs={6} md={3} className="mb-1_5">
          <Card
            tag={Link}
            to={getVehicleComparisonUrl(selectedStyles)}
            className="placeholder-card overflow-hidden h-100"
            data-tracking-id="compare_cars_any"
          >
            <AspectRatioContainer aspectRatio="3:2" className="mb-0_5">
              <div>
                <div className="plus-container bg-blue-90 mx-auto pos-r">
                  <i className="icon-plus3 text-primary-darker pos-a center-xy" aria-hidden />
                </div>
              </div>
            </AspectRatioContainer>
            <div className="mb-0_5 px-1_5 text-center text-primary-darker d-inline-block fw-bold size-16 text-underline px-0_5">
              Add any vehicle to compare
            </div>
          </Card>
        </Col>
      </ul>
      {hasComparisons && (
        <div className="compare-links d-flex flex-column align-items-center align-items-md-start mb-2">
          <Button
            tag={Link}
            to={getVehicleComparisonUrl(selectedStyles)}
            className="compare-button size-16 py-0_25 px-1 text-transform-none mb-1"
            color="primary-b"
            disabled={!selectedStyles.length}
            data-tracking-id="compare_cars_custom"
          >
            Compare selected vehicles
          </Button>
          {!!get(segmentRating, 'editorialSegment.displayName') && (
            <ArrowLink
              className="mt-0_75"
              to={buildRankingsSubsegmentLink(
                segmentRating.editorialSegment.edmundsTypeCategory,
                segmentRating.editorialSegment.displayName
              )}
              data-tracking-id="compare_vehicle_ranking"
            >
              See full list of {segmentRating.editorialSegment.displayName} rankings
            </ArrowLink>
          )}
        </div>
      )}
      {!!get(textComparisons, 'length') && (
        <div>
          {comparisons.map((comparison, index) => {
            const key = `comp-${index + 1}`;
            const { make, model, style } = comparison.vehicles[0];
            const makeModelVsMakeModelLink = getValidMakeModelVsMakeModelLink(
              comparison.vehicles[0],
              comparison.vehicles[1]
            );

            return (
              comparison.content && (
                <Fragment key={key}>
                  <h3 className="mb-0 align-self-center heading-5 mt-1_5 mb-0_5">
                    {make} {model} vs. {comparison.vehicles[1].make} {comparison.vehicles[1].model}
                  </h3>
                  <ContentFragment classes="mb-0_5 mb-md-1 size-16">{comparison.content}</ContentFragment>
                  <ArrowLink
                    to={makeModelVsMakeModelLink || getVehicleComparisonUrl([style, comparison.vehicles[1].style])}
                    data-tracking-id="compare_cars"
                    data-tracking-value={`compare ${model} vs ${comparison.vehicles[1].model}`}
                  >
                    Compare {make} {model} & {comparison.vehicles[1].make} {comparison.vehicles[1].model} features
                  </ArrowLink>
                </Fragment>
              )
            );
          })}
        </div>
      )}
    </div>
  );
}

VehicleComparisonsUI.propTypes = {
  vehicleComparisons: VehicleComparisonsEntities.ComparisonsContent,
  segmentRating: TypeRankingsEntities.SegmentRating,
  sharedTrimName: PropTypes.string,
  styles: VehicleEntities.PopularStyles,
};

VehicleComparisonsUI.defaultProps = {
  vehicleComparisons: null,
  segmentRating: null,
  sharedTrimName: null,
  styles: null,
};

export const VehicleComparisons = connectToModel(VehicleComparisonsUI, {
  vehicleComparisons: bindToPath(
    ({ vehicle }) => buildComparisonsPath(getParamsFromVehicle(vehicle)),
    VehicleDetailsModel
  ),
  segmentRating: bindToPath(
    ({
      vehicle: {
        make: { slug: makeSlug },
        model: { slug: modelSlug },
        year,
      },
    }) => buildSegmentRatingsPath({ makeSlug, modelSlug, year }),
    TypeRankingsFeaturesModel,
    (
      segmentRatings,
      {
        vehicle: {
          submodels: { slug: submodelSlug },
        },
      }
    ) => getBestSegmentRating(segmentRatings, { submodelSlug })
  ),
  sharedTrimName: bindToPath('sharedValues.trimName', PageModel),
  styles: bindToPath(
    ({ vehicle }) => buildPopularStylesPath(getParamsFromVehicle(vehicle)),
    VehicleModel,
    popularStyles => orderBy(popularStyles, 'price.baseMSRP')
  ),
});

VehicleComparisons.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
};
