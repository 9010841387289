import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InventoryAggregateEntities } from 'client/data/models/inventory-aggregate';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import {
  generateLeaseSrpLinkMakeModel,
  generateNewFlatUrl,
  generateSrpLinkMakeModel,
} from 'site-modules/shared/utils/srp-link-constructor';
import { getDealsUrl } from 'site-modules/shared/utils/core-link-constructor';
import { getBuildAndPriceLink } from 'site-modules/shared/utils/build-and-price-link-constructor';
import { getPriceCheckerLink } from 'site-modules/shared/utils/price-checker-link-constructor';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';

const SHOPPING_LINKS_CONFIG = {
  LEASE: {
    label: 'Lease Offers',
    iconClassName: 'icon-appraisal',
  },
  DEALS: {
    label: 'Deals & Incentives',
    iconClassName: 'icon-coins',
  },
  USED_INVENTORY: {
    label: 'Shop Used',
    iconClassName: 'icon-price-tag3',
  },
  BUILD_PRICE: {
    label: 'Build & Price',
    iconClassName: 'icon-wrench2',
  },
  SRP: {
    label: 'See All for Sale',
    iconClassName: 'icon-price-tags2',
  },
  PRICE_CHECKER: {
    label: 'Price Checker™',
    iconClassName: 'icon-price-tag',
  },
};

export function ShoppingLinks({ params, vehicle, className, inventoryTotalNumber }) {
  const {
    make: { slug: makeSlug },
    model: { slug: modelSlug },
    year,
  } = vehicle;

  return (
    <div className={classnames('shopping-links', className)} data-tracking-parent="pricing-related-links">
      <h2 className="heading-5 mb-0">Helpful shopping links</h2>
      <ul className="links-wrapper list-unstyled">
        <li className="mt-1">
          <ShoppingLink
            {...SHOPPING_LINKS_CONFIG.SRP}
            to={generateNewFlatUrl(params)}
            data-tracking-id="view_search_result"
          />
        </li>
        <li className="mt-1">
          <ShoppingLink
            {...SHOPPING_LINKS_CONFIG.BUILD_PRICE}
            to={getBuildAndPriceLink(params)}
            data-tracking-id="configure_vehicle"
            rel="nofollow"
          />
        </li>
        <li className="mt-1">
          <ShoppingLink
            {...SHOPPING_LINKS_CONFIG.DEALS}
            to={getDealsUrl({ makeSlug, modelSlug, year })}
            data-tracking-id="view_incentives_deals"
          />
        </li>
        {!!inventoryTotalNumber && (
          <li className="mt-1">
            <ShoppingLink
              {...SHOPPING_LINKS_CONFIG.LEASE}
              to={generateLeaseSrpLinkMakeModel(makeSlug, modelSlug)}
              data-tracking-id="lease_offers_srp_link"
            />
          </li>
        )}
        <li className="mt-1">
          <ShoppingLink
            {...SHOPPING_LINKS_CONFIG.PRICE_CHECKER}
            to={getPriceCheckerLink({ make: makeSlug, model: modelSlug, year })}
            rel="nofollow"
            data-tracking-id="core_page_go_to_price_checker"
            data-tracking-value="Price Checker"
          />
        </li>
        <li className="mt-1">
          <ShoppingLink
            {...SHOPPING_LINKS_CONFIG.USED_INVENTORY}
            to={generateSrpLinkMakeModel(makeSlug, modelSlug)}
            data-tracking-id="view_search_result"
          />
        </li>
      </ul>
    </div>
  );
}

ShoppingLinks.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  usedInventoryResults: InventoryAggregateEntities.MMYSubmodelTypeAmounts,
  inventoryTotalNumber: PropTypes.number,
  className: PropTypes.string,
};

ShoppingLinks.defaultProps = {
  usedInventoryResults: null,
  inventoryTotalNumber: null,
  className: null,
};
