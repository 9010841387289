import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { getAdImageUrl } from 'site-modules/shared/components/native-ad/utils/utils';
import { Styles } from 'site-modules/shared/components/native-ad/buildprice-site-served-ad/buildprice-site-served-ad-styles';

const THEMES = {
  tech: 'technology',
  comfort: 'comfort features',
  safety: 'safety features',
};

const renderJellybean = (vehicleImg, photoDisclaimer) => (
  <figure style={Styles.imgWrapper} className="mx-auto mb-0 d-flex justify-content-center align-items-center pos-r">
    <img style={Styles.coreImg} className="img-fluid rounded" src={vehicleImg} alt="" />
    {photoDisclaimer && (
      <figcaption className="w-100 text-end size-12 pos-a mb-0_25" style={Styles.figcaption}>
        {photoDisclaimer}
      </figcaption>
    )}
  </figure>
);

export function BuildPriceFeatures({ creativeConfigData, features, mobile }) {
  const {
    cta,
    linkDisplayUrl,
    photoDisclaimer,
    isConquest,
    vehicleInfo: { makeName, modelName },
    photo,
    theme,
  } = creativeConfigData;
  const vehicleImg = getAdImageUrl(photo);

  return (
    <Row>
      <Col xs={12} md={7} tag="section">
        {isConquest && (
          <ConquestRibbon
            classes="mt-0_5 is-large"
            style={{ height: '24px', lineHeight: '24px', top: '-16px', left: '-32px' }}
            isLarge
          />
        )}
        {mobile && renderJellybean(vehicleImg, photoDisclaimer)}
        <header
          className={classnames('headline fw-bold text-gray-darkest', {
            'size-20': !mobile,
            'mt-2_5': mobile,
          })}
        >
          Equip your {makeName} {modelName} with the latest {THEMES[theme]}
        </header>
        <ul className="list-unstyled my-1_25 text-gray-darker" aria-label={THEMES[theme]}>
          {features.map(feature => (
            <li className="mb-0_5" key={feature}>
              <i className="icon-checkmark text-primary-darker size-12 pr-0_5" aria-hidden="true" />
              {feature}
            </li>
          ))}
        </ul>
        <div className="d-flex flex-column flex-md-row align-items-center">
          <span
            className={classnames(
              'btn btn-sm btn-outline-blue-50 py-0_75 px-2_5 size-16 fw-medium pos-r d-inline-block mb-0_5 mb-md-0 mr-md-0_5',
              { 'w-100': mobile }
            )}
          >
            <CtaButtonText text={cta} />
          </span>
          <span className="medium text-blue-50 pos-r d-block oem-url-wrapper">{linkDisplayUrl}</span>
        </div>
      </Col>
      {!mobile && (
        <Col xs={12} md={5} tag="section" className="d-flex flex-column justify-content-center">
          {renderJellybean(vehicleImg, photoDisclaimer)}
        </Col>
      )}
    </Row>
  );
}

BuildPriceFeatures.propTypes = {
  creativeConfigData: PropTypes.shape({}).isRequired,
  mobile: PropTypes.bool.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};
