import PropTypes from 'prop-types';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { EdmundsAPI } from 'client/data/api/api-client';
import { buildNoSubmodelVehiclePath } from './vehicle';

const NhtsaRatings = PropTypes.shape({
  overall: PropTypes.number,
  frontalBarrierCrash: PropTypes.shape({
    overall: PropTypes.number,
    driver: PropTypes.number,
    passenger: PropTypes.number,
  }),
  sideCrash: PropTypes.shape({
    overall: PropTypes.number,
  }),
  sideBarrierCrash: PropTypes.shape({
    overall: PropTypes.number,
    driver: PropTypes.number,
    passenger: PropTypes.number,
    sidePoleRating: PropTypes.number,
  }),
  sideBarrierAndPoleCrash: PropTypes.shape({
    frontSeat: PropTypes.number,
    backSeat: PropTypes.number,
  }),
  rollover: PropTypes.shape({
    rollover: PropTypes.number,
    dynamicTestResult: PropTypes.string,
    riskOfRolloverPercent: PropTypes.number,
  }),
});

const IihsRatings = PropTypes.shape({
  sideImpact: PropTypes.string,
  roofStrength: PropTypes.string,
  rearHeadProtectionAndHeadRestraint: PropTypes.string,
  smallOverlapFront: PropTypes.string,
  moderateOverlapFront: PropTypes.string,
});

const SafetyRatings = PropTypes.shape({
  NhtsaRatings,
  IihsRatings,
  jdPowerRatings: PropTypes.shape({
    year: PropTypes.number,
    overallQuality: PropTypes.string,
    powerTrainMechanicalQuality: PropTypes.string,
    overallDesignQuality: PropTypes.string,
    featuresAndAccessDesignQuality: PropTypes.string,
    powerTrainDesignQuality: PropTypes.string,
    overallMechanicalQuality: PropTypes.string,
    bodyAndInteriorMechanicalQuality: PropTypes.string,
    featuresAndAccessMechanicalQuality: PropTypes.string,
    bodyAndInteriorDesignQuality: PropTypes.string,
  }),
});

export const SafetyEntities = {
  Safety: PropTypes.shape({
    styleId: PropTypes.number,
    submodel: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      name: PropTypes.string,
    }),
    ratings: SafetyRatings,
  }),
  Ratings: SafetyRatings,
  NhtsaRatings,
  IihsRatings,
};

/**
 * @param {
 *   params {
 *    make: (string)
 *    model: (string)
 *    year: (string)
 *  }
 * } - object containing make, model, year
 *
 * @returns {string}
 */
export function buildSafetyRatingsPathByParams({ params }) {
  return `${buildNoSubmodelVehiclePath(params)}.ratings`;
}

export const SafetyModel = createModelSegment('safety', [
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].ratings',
    async resolve(match, context) {
      const { make, model, year } = match;
      const url = `/vehicle/v3/${make}/${model}/${year}/safetyandreliabilityratings`;
      const { ratings } = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return ratings;
    },
  },
]);
