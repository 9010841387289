import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Accordion } from 'site-modules/shared/components/accordion/accordion';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { SCROLL_DURATION, SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingConstant } from 'client/tracking/constant';
import { insertLearnMoreLink } from './utils';
import { WRAPPER_TYPES } from './constants';

import './faq.scss';

const onExpand = (value, creativeId) => {
  if (value) {
    TrackingHandler.fireEvent('view_faq_questions', {
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: creativeId,
        value: value.replace('?', ''),
      },
    });
  }
};

function FaqEntriesWrapper({
  wrapperType,
  headingLabelComponent,
  visibleEntriesNum,
  children,
  entriesWrapperClassName,
  accordionQuestionClassName,
  accordionAnswerClassName,
  creativeId,
  collapseToggleSrExplanation,
}) {
  let visibleContent = children;
  let restContent = [];

  function onAccordionExpand(value) {
    onExpand(value, creativeId);
  }

  switch (wrapperType) {
    case WRAPPER_TYPES.accordion:
      if (visibleEntriesNum) {
        visibleContent = children.slice(0, visibleEntriesNum);
        restContent = children.slice(visibleEntriesNum);
      }

      return (
        <Fragment>
          <Accordion
            tabClasses={entriesWrapperClassName}
            headingLabelComponent={headingLabelComponent}
            onExpand={onAccordionExpand}
            className={classnames({ 'mb-0': !!restContent.length })}
            headerClasses={accordionQuestionClassName}
            itemClasses={accordionAnswerClassName}
          >
            {visibleContent}
          </Accordion>
          {!!restContent.length && (
            <ContentCollapse
              isEclipseFade={false}
              btnClasses="px-0_5 py-0 size-16 text-primary-darker"
              btnContainerClasses="py-0"
              justify="justify-content-start"
              showBtnBelow
              btnTextClasses="fw-normal"
              resetParams={{
                offset: SCROLL_OFFSET,
                duration: SCROLL_DURATION,
              }}
            >
              <Accordion headingLabelComponent={headingLabelComponent} onExpand={onAccordionExpand}>
                {restContent}
              </Accordion>
            </ContentCollapse>
          )}
        </Fragment>
      );
    case WRAPPER_TYPES.collapse:
      visibleContent = children.slice(0, visibleEntriesNum || 2);
      restContent = children.slice(visibleEntriesNum || 2);
      return (
        <Fragment>
          <dl className="mb-0">{visibleContent}</dl>
          {!!restContent.length && (
            <ContentCollapse
              isEclipseFade={false}
              btnClasses="px-0_5 py-0 size-16 text-primary-darker"
              btnContainerClasses="py-0"
              justify="justify-content-start"
              showBtnBelow
              btnTextClasses="fw-normal"
              resetParams={{
                offset: SCROLL_OFFSET,
                duration: SCROLL_DURATION,
              }}
              collapseToggleSrExplanation={collapseToggleSrExplanation}
            >
              <dl className="mb-0">{restContent}</dl>
            </ContentCollapse>
          )}
        </Fragment>
      );
    default:
      return <dl className="mb-0">{children}</dl>;
  }
}

FaqEntriesWrapper.propTypes = {
  wrapperType: PropTypes.string,
  headingLabelComponent: PropTypes.string,
  children: PropTypes.node,
  entriesWrapperClassName: PropTypes.string,
  accordionQuestionClassName: PropTypes.string,
  creativeId: PropTypes.string.isRequired,
  collapseToggleSrExplanation: PropTypes.string,
};

FaqEntriesWrapper.defaultProps = {
  wrapperType: '',
  headingLabelComponent: undefined,
  children: null,
  entriesWrapperClassName: undefined,
  accordionQuestionClassName: undefined,
  collapseToggleSrExplanation: undefined,
};

export function Answer({ content, learnMoreLink, pathname, className, omitContentFragment }) {
  if (omitContentFragment) return <div className={classnames('answer', className)}>{content}</div>;

  return Array.isArray(content) ? (
    content.map((p, ind) => (
      <ContentFragment
        componentToUse="div"
        classes={classnames('answer', className)}
        key={p.substr(0, 4) + p.substr(p.length - 3)}
      >
        {ind === content.length - 1 ? insertLearnMoreLink({ answer: p, pathname, learnMoreLink }) : p}
      </ContentFragment>
    ))
  ) : (
    <ContentFragment componentToUse="div" classes={classnames('answer', className)}>
      {insertLearnMoreLink({ answer: content, pathname, learnMoreLink })}
    </ContentFragment>
  );
}

Answer.propTypes = {
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.node]),
  learnMoreLink: PropTypes.string,
  pathname: PropTypes.string,
  className: PropTypes.string,
  omitContentFragment: PropTypes.bool,
};

Answer.defaultProps = {
  content: '',
  learnMoreLink: '',
  pathname: '',
  className: 'size-16 mb-1',
  omitContentFragment: false,
};

export function Faq({
  entries,
  title,
  caption,
  wrapperType,
  questionKey,
  answerKey,
  creativeId,
  className,
  titleClassName,
  entriesWrapperClassName,
  accordionQuestionClassName,
  accordionAnswerClassName,
  questionClassName,
  answerClassName,
  coloredLinks,
  titleComponent,
  questionComponent,
  visibleEntriesNum,
  collapseToggleSrExplanation,
}) {
  if (!entries || !entries.length) return null;

  const hasAccordion = wrapperType === WRAPPER_TYPES.accordion;
  const Title = titleComponent;
  const Question = questionComponent;

  return (
    <section className={classnames(className, { 'links-colored': coloredLinks })} data-tracking-parent={creativeId}>
      {title && <Title className={classnames(titleClassName, { 'mb-1_5': !caption && !hasAccordion })}>{title}</Title>}
      {caption && <ContentFragment>{caption}</ContentFragment>}
      <FaqEntriesWrapper
        wrapperType={wrapperType}
        headingLabelComponent={questionComponent}
        visibleEntriesNum={visibleEntriesNum}
        entriesWrapperClassName={entriesWrapperClassName}
        accordionQuestionClassName={accordionQuestionClassName}
        accordionAnswerClassName={accordionAnswerClassName}
        creativeId={creativeId}
        collapseToggleSrExplanation={collapseToggleSrExplanation}
      >
        {entries.map(({ [questionKey]: question, [answerKey]: answer, omitContentFragment }) =>
          hasAccordion ? (
            <div id={question} label={question} key={question}>
              <Answer content={answer} className={answerClassName} omitContentFragment={omitContentFragment} />
            </div>
          ) : (
            <Fragment key={question}>
              <dt>
                <Question className={classnames('mb-0', questionClassName)}>{question}</Question>
              </dt>
              <dd>
                <Answer content={answer} className={answerClassName} omitContentFragment={omitContentFragment} />
              </dd>
            </Fragment>
          )
        )}
      </FaqEntriesWrapper>
    </section>
  );
}

Faq.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.node]),
      omitContentFragment: PropTypes.bool,
    })
  ),
  title: PropTypes.string,
  caption: PropTypes.string,
  wrapperType: PropTypes.string,
  questionKey: PropTypes.string,
  answerKey: PropTypes.string,
  creativeId: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  entriesWrapperClassName: PropTypes.string,
  accordionQuestionClassName: PropTypes.string,
  accordionAnswerClassName: PropTypes.string,
  questionClassName: PropTypes.string,
  answerClassName: PropTypes.string,
  coloredLinks: PropTypes.bool,
  titleComponent: PropTypes.string,
  questionComponent: PropTypes.string,
  visibleEntriesNum: PropTypes.number,
  collapseToggleSrExplanation: PropTypes.string,
};

Faq.defaultProps = {
  entries: [],
  title: 'FAQ',
  caption: '',
  wrapperType: WRAPPER_TYPES.accordion,
  questionKey: 'question',
  answerKey: 'answer',
  creativeId: 'edm-entry-faq',
  className: 'faq text-gray-darker',
  titleClassName: 'heading-3',
  entriesWrapperClassName: undefined,
  accordionQuestionClassName: undefined,
  accordionAnswerClassName: undefined,
  questionClassName: 'heading-5',
  answerClassName: 'size-16 mb-1',
  coloredLinks: false,
  titleComponent: 'h2',
  questionComponent: 'h3',
  visibleEntriesNum: null,
  collapseToggleSrExplanation: undefined,
};
