import { progressiveComponent } from 'site-modules/shared/components/progressive-component/progressive-component';
import { RenderOnInteraction } from 'site-modules/shared/components/render-on-interaction/render-on-interaction';

/* Seo Components */
import { SeoSafety } from 'site-modules/core-page/components/safety/seo-safety';
import { SeoFaqSection } from 'site-modules/core-page/components/faq-section/seo-faq-section';

export default {
  /* Progressive Safety */
  Safety: progressiveComponent({
    SEOComponent: SeoSafety,
    fullComponent: {
      loader: () => import(/* webpackChunkName: "safety" */ 'site-modules/core-page/components/safety/safety'),
      name: 'Safety',
    },
    Renderer: RenderOnInteraction,
    interactionEvents: ['keydown', 'scroll', 'mousedown', 'touchstart', 'mousemove'],
    dataTrackingParent: 'edm-entry-safety',
  }),

  /* Progressive FAQ */
  FaqSection: progressiveComponent({
    SEOComponent: SeoFaqSection,
    fullComponent: {
      loader: () =>
        import(/* webpackChunkName: "faq-section" */ 'site-modules/core-page/components/faq-section/faq-section'),
      name: 'FaqSection',
    },
  }),
};
