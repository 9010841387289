import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'site-modules/shared/utils/storage';
import { IS_APPOINTMENT_SURVEY_APPEARED_STORAGE_KEY } from 'site-modules/shared/constants/appointment-survey';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { AppointmentSurvey } from './appointment-survey';

export function AppointmentSurveyExperiment({ experimentName, ...props }) {
  const [localStorage] = useState(new Storage('localStorage'));
  const [isAppointmentSurveyAppeared] = useState(localStorage.get(IS_APPOINTMENT_SURVEY_APPEARED_STORAGE_KEY));

  return (
    !isAppointmentSurveyAppeared && (
      <Experiment name={experimentName} showDefault>
        <Recipe name="ctrl" isDefault />
        <Recipe name="chal-1">
          <AppointmentSurvey localStorage={localStorage} {...props} />
        </Recipe>
      </Experiment>
    )
  );
}

AppointmentSurveyExperiment.propTypes = {
  experimentName: PropTypes.string.isRequired,
};
