import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Answer } from './faq';

export function SeoFaq({
  entries,
  title,
  generateLearnMoreLinks,
  withTracking,
  learnMoreLinkText,
  pathname,
  titleComponent,
  questionComponent,
}) {
  if (!entries || !entries.length) return null;

  const Title = titleComponent;
  const Question = questionComponent;

  return (
    <section className="faq text-gray-darker mb-1">
      {title && <Title className="heading-3">{title}</Title>}
      {entries.map(({ question, answer, learnMoreOverride }, ind) => (
        <details key={question}>
          <summary className="py-0_5">
            <Question className="size-16 fw-normal" style={{ display: 'inline' }}>
              {question}
            </Question>
          </summary>
          <Answer
            content={answer}
            learnMoreLink={
              generateLearnMoreLinks
                ? `<a href="${get(learnMoreOverride, 'pathname', pathname)}${
                    withTracking
                      ? `?utm_source=google&utm_medium=organic_search&utm_campaign=faq_zero_box&utm_content=link${ind +
                          1}`
                      : ''
                  }">${get(learnMoreOverride, 'linkText', learnMoreLinkText)}</a>`
                : ''
            }
            pathname={pathname}
          />
        </details>
      ))}
    </section>
  );
}

SeoFaq.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    })
  ),
  title: PropTypes.string,
  generateLearnMoreLinks: PropTypes.bool,
  learnMoreLinkText: PropTypes.string,
  pathname: PropTypes.string,
  titleComponent: PropTypes.string,
  questionComponent: PropTypes.string,
  withTracking: PropTypes.bool,
};

SeoFaq.defaultProps = {
  entries: [],
  title: 'FAQ',
  generateLearnMoreLinks: true,
  learnMoreLinkText: 'Learn more',
  pathname: '',
  questionClassName: 'heading-5',
  answerClassName: 'size-16 mb-1',
  titleComponent: 'h2',
  questionComponent: 'h3',
  withTracking: false,
};
