import { get, compact } from 'lodash';
import { formatPriceString, getPriceString } from 'site-modules/shared/utils/price-utils';
import { getFeatureValue } from 'site-modules/shared/utils/features-specs/utils';
import { getDealsUrl } from 'site-modules/shared/utils/core-link-constructor';

function getLowestAndHighestCombinedMpg(stylesList) {
  return stylesList.reduce(
    (result, style) => {
      const styleCombinedMpg = +getFeatureValue(
        get(style, 'features'),
        'standard.Fuel',
        'Combined MPG',
        getFeatureValue(get(style, 'features'), 'standard.Fuel', 'EPA combined MPG', 0)
      );
      const { highestCombinedMpg, lowestCombinedMpg } = result;
      return {
        highestCombinedMpg: styleCombinedMpg > highestCombinedMpg ? styleCombinedMpg : highestCombinedMpg,
        lowestCombinedMpg:
          styleCombinedMpg && (!lowestCombinedMpg || styleCombinedMpg < lowestCombinedMpg)
            ? styleCombinedMpg
            : lowestCombinedMpg,
      };
    },
    { highestCombinedMpg: 0, lowestCombinedMpg: 0 }
  );
}

function getLowestAndCargoCapacityMpg(stylesList) {
  return stylesList.reduce(
    (result, style) => {
      const styleCargoCapacityString = getFeatureValue(
        get(style, 'features'),
        'standard.Measurements',
        'Cargo capacity, all seats in place',
        ''
      );
      const styleCargoCapacity = styleCargoCapacityString.split(' ')[0];
      const { highestCargoCapacity, lowestCargoCapacity } = result;
      return {
        highestCargoCapacity: styleCargoCapacity > highestCargoCapacity ? styleCargoCapacity : highestCargoCapacity,
        lowestCargoCapacity:
          styleCargoCapacity && (!lowestCargoCapacity || styleCargoCapacity < lowestCargoCapacity)
            ? styleCargoCapacity
            : lowestCargoCapacity,
      };
    },
    { highestCargoCapacity: 0, lowestCargoCapacity: 0 }
  );
}

function getCheapestStyle(stylesList) {
  return stylesList.reduce(
    (result, style) => {
      const stylePrice = get(style, 'price.baseMSRP', 0);
      const { price } = result;
      return !price || stylePrice < price
        ? {
            price: stylePrice,
            styleName: get(style, 'name'),
          }
        : result;
    },
    { price: 0, styleName: '' }
  );
}

function getMMGoodCarEntry({
  make,
  model,
  year,
  editorialOverallRating,
  averageConsumerRating,
  numberOfReviews,
  highestCombinedMpg,
  lowestCombinedMpg,
  highestCargoCapacity,
  lowestCargoCapacity,
  isElectric,
}) {
  const answer = [
    `The Edmunds experts tested the ${year} ${model} both on the road and at the track${
      editorialOverallRating ? `, giving it a ${editorialOverallRating} out of 10` : ''
    }.`,
  ];

  if (averageConsumerRating) {
    answer.push(
      `Edmunds’ consumer reviews show that the ${year} ${model} gets an average rating of ${averageConsumerRating.toFixed()} stars out of 5 (based on ${numberOfReviews} reviews)`
    );
  }

  if (lowestCombinedMpg) {
    const mpg = isElectric ? 'mpg-e' : 'mpg';
    answer.push(
      `You probably care about ${make} ${model} ${
        isElectric ? 'energy consumption' : 'fuel economy'
      }, so it's important to know that the ${model} gets an EPA-estimated ${lowestCombinedMpg} ${mpg}${
        highestCombinedMpg !== lowestCombinedMpg
          ? ` to ${highestCombinedMpg} ${mpg}, depending on the configuration`
          : ''
      }.`
    );
  }

  if (lowestCargoCapacity) {
    answer.push(
      `What about cargo capacity? When you're thinking about carrying stuff in your new car, keep in mind that ${
        lowestCargoCapacity !== highestCargoCapacity
          ? `carrying capacity for the ${model} ranges from ${lowestCargoCapacity} to ${highestCargoCapacity}`
          : `the ${model} has ${lowestCargoCapacity}`
      } cubic feet of trunk space.`
    );
  }

  answer.push(
    `And then there's safety and reliability. Edmunds has all the latest NHTSA and IIHS crash-test scores, plus industry-leading expert and consumer reviews to help you understand what it's like to own and maintain a ${make} ${model}.`
  );

  return {
    question: `Is the ${make} ${model} a good car?`,
    answer: answer.join(' '),
  };
}

function getWhatsNewEntry({ year, make, model, whatsNewContent }) {
  return (
    whatsNewContent &&
    whatsNewContent.length && {
      question: `What's new in the ${year} ${make} ${model}?`,
      answer: `<p>According to Edmunds’ car experts, here’s what’s new for the ${year} ${make} ${model}:</p>
      <ul>
        ${whatsNewContent.map(item => `<li>${item}</li>`).join('')}
      </ul>
    `,
    }
  );
}

function getReliabilityEntry({ make, model, averageConsumerRating }) {
  return {
    question: `Is the ${make} ${model} reliable?`,
    answer: `To determine whether the ${make} ${model} is reliable, read Edmunds' authentic consumer reviews, which come from real owners and reveal what it's like to live with the ${model}. Look for specific complaints that keep popping up in the reviews, and be sure to compare the ${model}'s ${
      averageConsumerRating ? ` ${averageConsumerRating.toFixed()}-star` : ''
    } average consumer rating to that of competing vehicles.`,
  };
}

function getYMMGoodCarEntry({ year, make, model, editorialOverallRating, averageConsumerRating, numberOfReviews }) {
  const answer = [`There's a lot to consider if you're wondering whether the ${year} ${make} ${model} is a good car.`];

  if (editorialOverallRating) {
    answer.push(
      `Edmunds' expert testing team reviewed the ${year} ${model} and gave it a ${editorialOverallRating} out of 10.`
    );
  }

  if (averageConsumerRating) {
    answer.push(
      `Our consumer reviews show that the ${year} ${model} gets an average rating of ${averageConsumerRating.toFixed()} stars out of 5 (based on ${numberOfReviews} reviews).`
    );
  }

  answer.push(
    `Safety scores, fuel economy, cargo capacity and feature availability should all be factors in determining whether the ${year} ${model} is a good car for you.`
  );

  if (!editorialOverallRating && !averageConsumerRating) {
    answer.push(`Check back soon for the official Edmunds Rating from our expert testing team`);
  }

  return {
    question: `Is the ${year} ${make} ${model} a good car?`,
    answer: answer.join(' '),
  };
}

function getPaymentEntry({ year, make, model, price, styleName, stylesList }) {
  const answer = [
    `<p>The least-expensive ${year} ${make} ${model} is the ${year} ${make} ${model} ${styleName}. Including destination charge, it arrives with a Manufacturer's Suggested Retail Price (MSRP) of about ${formatPriceString(
      price
    )}.</p>`,
  ];

  answer.push(
    `<p>Other versions include:</p><ul>${stylesList
      .map(style => {
        const msrp = get(style, 'price.baseMSRP');
        return msrp ? `<li>${style.name} which starts at ${formatPriceString(style.price.baseMSRP)}</li>` : '';
      })
      .join('')}</ul>`
  );

  return {
    question: `How much should I pay for a ${year} ${make} ${model}?`,
    answer: answer.join(''),
  };
}

function getModelsEntry({ make, model, stylesList }) {
  const shortStylesList = stylesList.slice(0, 4);
  const stylesNames = shortStylesList
    .map((style, ind) =>
      ind + 1 === shortStylesList.length && shortStylesList.length > 1
        ? `and ${get(style, 'name')}`
        : get(style, 'name')
    )
    .join(', ');

  return {
    question: `What are the different models of ${make} ${model}?`,
    answer: `If you're interested in the ${make} ${model}, the next question is, which ${model} model is right for you? ${model} variants include ${stylesNames}. For a full list of ${model} models, check out Edmunds’ Features & Specs page.`,
  };
}

function getTaxRebateEntry({ taxRebateAmount, make, model, year, makeSlug, modelSlug }) {
  return (
    taxRebateAmount && {
      question: `Is there an EV tax credit or rebate for the ${year} ${make} ${model}`,
      answer: `The ${year} ${make} ${model} qualifies for a total of ${getPriceString(
        taxRebateAmount
      )} in tax credits and rebates.`,
      learnMoreOverride: {
        pathname: getDealsUrl({ makeSlug, modelSlug, year }),
        linkText: `See all incentives for the ${year} ${make} ${model}`,
      },
    }
  );
}

export function getFaqEntries({
  vehicle,
  editorialReview,
  consumerReviews,
  ratingsCount,
  whatsNewContent,
  taxRebateAmount,
  stylesList = [],
}) {
  const make = get(vehicle, 'make.name');
  const model = get(vehicle, 'model.name');
  const makeSlug = get(vehicle, 'make.slug');
  const modelSlug = get(vehicle, 'model.slug');
  const year = get(vehicle, 'year');
  const editorialOverallRating = get(editorialReview, 'ratings.overall.rating');
  const averageConsumerRating = get(ratingsCount, 'ratingAggregation.averageStars');
  const numberOfReviews = get(consumerReviews, 'totalReviews');
  const isElectric =
    get(stylesList, '[0].styleAttributes.electric') ||
    getFeatureValue(get(stylesList, '[0].features'), 'standard.Engine', 'Base engine type') === 'electric';
  const { highestCombinedMpg, lowestCombinedMpg } = getLowestAndHighestCombinedMpg(stylesList);
  const { highestCargoCapacity, lowestCargoCapacity } = getLowestAndCargoCapacityMpg(stylesList);
  const { price, styleName } = getCheapestStyle(stylesList);

  return compact([
    getMMGoodCarEntry({
      make,
      model,
      year,
      editorialOverallRating,
      averageConsumerRating,
      numberOfReviews,
      highestCombinedMpg,
      lowestCombinedMpg,
      highestCargoCapacity,
      lowestCargoCapacity,
      isElectric,
    }),
    getWhatsNewEntry({ year, make, model, whatsNewContent }),
    getReliabilityEntry({ make, model, averageConsumerRating }),
    getYMMGoodCarEntry({ year, make, model, editorialOverallRating, averageConsumerRating, numberOfReviews }),
    getPaymentEntry({ year, make, model, price, styleName, stylesList }),
    getModelsEntry({ make, model, stylesList }),
    getTaxRebateEntry({ year, make, model, makeSlug, modelSlug, taxRebateAmount }),
  ]);
}
