import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export function Gradient({
  chartProps: { idPrefix, singleColSpace, yStartingPoint },
  colors,
  startPos,
  endPos,
  text,
  textClassName,
  textColor,
  textY,
  opacity,
  elementProps: { component: Component = 'g', ...otherElementProps },
}) {
  const startX = startPos * singleColSpace;
  const endX = endPos * singleColSpace;
  // If there are several gradients with same start pos - introduce gradientId prop
  const gradientId = `${idPrefix}gradient-${startPos}`;

  return (
    <Component className="gradient-overlay" {...otherElementProps}>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          {colors.map(({ offset, stopColor, stopOpacity }, key) => (
            <stop
              key={`color-${key}`} // eslint-disable-line react/no-array-index-key
              offset={offset}
              stopColor={stopColor}
              stopOpacity={stopOpacity}
            />
          ))}
        </linearGradient>
      </defs>
      <polygon
        opacity={opacity}
        points={`${startX},${yStartingPoint} ${startX},0 ${endX},0 ${endX},${yStartingPoint}`}
        fill={`url(#${gradientId})`}
      />
      {!!text && (
        <text
          fill={textColor}
          textAnchor="middle"
          dominantBaseline="middle"
          x={(startX + endX) / 2}
          y={textY || yStartingPoint - 16}
          className={classnames('gradient-text', textClassName)}
        >
          {text}
        </text>
      )}
    </Component>
  );
}

Gradient.propTypes = {
  chartProps: PropTypes.shape({
    idPrefix: PropTypes.string,
    singleColSpace: PropTypes.number,
    yStartingPoint: PropTypes.number,
  }).isRequired,
  startPos: PropTypes.number.isRequired,
  endPos: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      offset: PropTypes.string,
      stopColor: PropTypes.string,
      stopOpacity: PropTypes.string,
    })
  ).isRequired,
  text: PropTypes.string,
  textColor: PropTypes.string,
  textClassName: PropTypes.string,
  textY: PropTypes.number,
  opacity: PropTypes.number,
  elementProps: PropTypes.objectOf(PropTypes.any),
};

Gradient.defaultProps = {
  text: null,
  textColor: '#ffffff',
  textClassName: 'size-16 fw-medium',
  textY: null,
  opacity: null,
  elementProps: { component: 'g' },
};
