import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { BuildPriceSiteServedAd } from 'site-modules/shared/components/native-ad/buildprice-site-served-ad/buildprice-site-served-ad';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import {
  BuildPriceCreativeConfigPropTypes,
  BuildPriceCreativeConfigDefaultProps,
} from 'client/data/models/native-ads-creative-config';
import {
  AdComponentDefaultProps,
  AdComponentPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';

import { BUILD_PRICE_AD } from 'client/site-modules/shared/components/native-ad/utils/constants';
import { withSiteServedAdWrapper } from 'client/site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';

export function BuildPriceAdUI(props) {
  const {
    creativeConfig,
    currentVehicle,
    vehicle,
    mobile,
    includeMrectAdCall,
    wrapperClass,
    slotRenderEndListener,
    withDFPFallback,
    siteServedAdsEnabled,
    position,
    placeholderComponent,
    placeholderComponentProps,
    customTargeting,
    ivtClassName,
  } = props;

  const nativeAdProps = {
    refreshDisableWhenZipChanged: siteServedAdsEnabled,
    slotRenderEndListener,
    position,
    placeholderComponent,
    placeholderComponentProps,
    customTargeting,
    siteServedAdsUpdater: get(creativeConfig, 'siteServedAdsUpdater'),
    currentVehicle,
  };

  const adUnitWithDFPFallback = includeMrectAdCall ? (
    <NativeAd
      wrapperClass="build-price w-100"
      adNameWired="mrect,native"
      adNameMobile="mmrect,mnative"
      nativeStyle="buildprice,mediumrectangle"
      refreshable={false}
      customSizes={[[1, 1], [300, 250], 'fluid']}
      {...nativeAdProps}
    />
  ) : (
    <NativeAd nativeStyle="buildprice" wrapperClass="build-price w-100" {...nativeAdProps} />
  );

  return (
    <div className={classnames('build-price-unit', wrapperClass, ivtClassName)}>
      {withDFPFallback ? (
        adUnitWithDFPFallback
      ) : (
        <BuildPriceSiteServedAd
          mobile={mobile}
          position={position}
          creativeConfig={creativeConfig}
          vehicle={vehicle}
          currentVehicle={currentVehicle}
          customTargeting={customTargeting}
          wrapperClass="my-1"
        />
      )}
    </div>
  );
}

BuildPriceAdUI.propTypes = {
  ...AdComponentPropTypes,
  creativeConfig: BuildPriceCreativeConfigPropTypes,
  includeMrectAdCall: PropTypes.bool,
};

BuildPriceAdUI.defaultProps = {
  ...AdComponentDefaultProps,
  creativeConfig: BuildPriceCreativeConfigDefaultProps,
  includeMrectAdCall: false,
};

const BuildPriceAdWrapper = withSiteServedAdWrapper(BuildPriceAdUI);

export function BuildPriceAd(props) {
  return <BuildPriceAdWrapper {...props} adType={BUILD_PRICE_AD} />;
}
