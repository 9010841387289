import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, compact, concat } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildPreProdContentAuthorPath,
  buildPreProdContentPath,
  buildReviewPath,
  EditorialReviewEntities,
  EditorialReviewModel,
  FirstContentEntity,
} from 'client/data/models/editorial-review';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { PRE_PROD_ARTICLE_TYPE } from 'client/data/transforms/editorial-reviews/transform-make-model-year';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { FirstContentArticles } from 'site-modules/shared/components/core-page/articles/first-content-articles/first-content-articles';
import { PreProdContentArticles } from 'site-modules/shared/components/core-page/articles/pre-prod-content-articles/pre-prod-content-articles';
import { ArticleAuthor } from 'site-modules/shared/components/preprod-articles/article-author';
import './related-articles.scss';

export function RelatedArticlesUI({
  vehicle: {
    make: { name: makeName },
    model: { name: modelName },
    year,
  },
  firstContent,
  preProdContent,
  preProdContentAuthor,
  hasReviewContent,
  isMobile,
}) {
  let preProdArticles = [];
  if (hasReviewContent) {
    preProdArticles = [
      get(preProdContent, 'articles', []).filter(
        ({ type }) => ![PRE_PROD_ARTICLE_TYPE.TEASER, PRE_PROD_ARTICLE_TYPE.EDMUNDS_SAYS].includes(type)
      ),
      get(preProdContent, 'articles', []).filter(({ type }) => type === PRE_PROD_ARTICLE_TYPE.TEASER),
    ].filter(articles => articles.length);

    // Append 'edmunds-says' to the 1st section
    const edmundsSays = get(preProdContent, 'articles', []).find(
      ({ type }) => type === PRE_PROD_ARTICLE_TYPE.EDMUNDS_SAYS
    );
    preProdArticles[0] = compact(concat([], preProdArticles[0], edmundsSays));
  } else if (get(preProdContent, 'hasTeaser') && get(preProdContent, 'hasMiscellaneous')) {
    preProdArticles = [preProdContent.articles.filter(({ type }) => type === PRE_PROD_ARTICLE_TYPE.TEASER)];
  }

  preProdArticles = preProdArticles.filter(articles => articles.length);
  const hasPreProdContent = !!get(preProdContent, 'hasPreProdContent') && !!preProdArticles.length;

  useEffect(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: 'first_impressions_content',
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        value: `${!!get(firstContent, 'articles.length')},${hasPreProdContent}`,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!get(firstContent, 'articles.length') && !hasPreProdContent) {
    return null;
  }

  return (
    <div className="mb-2" data-tracking-parent="editorial-related-articles">
      <h2 className="main heading-3 mb-1_25">
        {year} {makeName} {modelName} First Impressions
      </h2>
      <div className="px-md-2_5 px-lg-0">
        <FirstContentArticles articles={get(firstContent, 'articles')} isMobile={isMobile} suppressFirstMedia />
        {hasPreProdContent &&
          preProdArticles.map((articles, index) => {
            const key = `preprod-${index}`;

            return (
              <Fragment key={key}>
                <ArticleAuthor
                  author={get(preProdContentAuthor, 'contentMetadata', {})}
                  date={`${get(preProdContent, 'articlePublishDate')} ${
                    get(preProdContent, 'articleUpdateDate') ? `(updated ${preProdContent.articleUpdateDate})` : ''
                  }`.trim()}
                  dateModified={get(preProdContent, 'dateModified')}
                  publishDate={get(preProdContent, 'publishDate')}
                  imageHeight={24}
                  className="medium"
                />
                <PreProdContentArticles
                  articles={articles}
                  titleClassName="heading-4"
                  collapseBodyText={articles.length > 2}
                  hasEdmundsSaysIcon
                  photoProps={{ allocateSpace: true, lazyLoad: true, imageSize: 815 }}
                />
                {index < preProdArticles.length - 1 && <hr className="mt-1_5 mb-2" />}
              </Fragment>
            );
          })}
      </div>
      <hr className="mt-1_5 mb-0" />
    </div>
  );
}

RelatedArticlesUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  firstContent: FirstContentEntity,
  preProdContent: FirstContentEntity,
  preProdContentAuthor: EditorialReviewEntities.Author,
  hasReviewContent: PropTypes.bool,
  isMobile: PropTypes.bool,
};
RelatedArticlesUI.defaultProps = {
  firstContent: null,
  preProdContent: null,
  preProdContentAuthor: null,
  hasReviewContent: false,
  isMobile: false,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const RelatedArticles = connect(mapStateToProps)(
  connectToModel(RelatedArticlesUI, {
    firstContent: bindToPath(
      ({ vehicle }) => buildReviewPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel,
      review => review && review.firstContent
    ),
    preProdContent: bindToPath(
      ({ vehicle, usePreProdContent }) => usePreProdContent && buildPreProdContentPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
    preProdContentAuthor: bindToPath(
      ({ vehicle, usePreProdContent }) =>
        usePreProdContent && buildPreProdContentAuthorPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
  })
);

RelatedArticles.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  usePreProdContent: PropTypes.bool,
  isMobile: PropTypes.bool,
};

RelatedArticles.defaultProps = {
  usePreProdContent: false,
  isMobile: false,
};
