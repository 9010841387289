import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { throttle } from 'lodash';

import './docked-container.scss';

export function DockedContainer({ children, showElementSelector, isAfterElement }) {
  const [isDisplayed, setDisplayed] = useState(null);

  useEffect(() => {
    const onScroll = throttle(() => {
      const showElement = document.querySelector(showElementSelector);
      if (!showElement) {
        return;
      }

      const { top, bottom } = showElement.getBoundingClientRect();

      setDisplayed(isAfterElement ? bottom < 0 : top < window.innerHeight);
    }, 100);

    const scrollElement = document.querySelector('.modal-content') || document;

    scrollElement.addEventListener('scroll', onScroll);

    return () => {
      scrollElement.removeEventListener('scroll', onScroll);
    };
  }, [isAfterElement, showElementSelector]);

  if (isDisplayed === null) {
    return null;
  }

  return (
    <div
      className={classnames('docked-container bg-primary-darker px-1 py-0_5 bottom-0 left-0 w-100', {
        show: isDisplayed,
      })}
    >
      {children}
    </div>
  );
}

DockedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  showElementSelector: PropTypes.string.isRequired,
  isAfterElement: PropTypes.bool,
};

DockedContainer.defaultProps = {
  isAfterElement: false,
};
