import React from 'react';
import PropTypes from 'prop-types';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { generateNewFlatUrl, generateSrpLinkMakeModel } from 'site-modules/shared/utils/srp-link-constructor';
import { DockedContainer } from 'site-modules/shared/components/docked-container/docked-container';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';

import './docked-srp-link.scss';

export function DockedSrpLink({ params, showElementSelector, inventoryTotalNumber, creativeId, trackingId }) {
  if (params.isDealerlessOem && !inventoryTotalNumber) {
    return null;
  }

  return (
    <DockedContainer showElementSelector={showElementSelector}>
      <ShoppingLink
        data-tracking-id={trackingId}
        creativeId={creativeId}
        className="docked-srp-link px-1 py-0_75 bg-white rounded"
        iconClassName="icon-price-tags2"
        iconStyles={{ height: '40px', width: '40px' }}
        {...(params.isDealerlessOem
          ? { to: generateSrpLinkMakeModel(params.make, params.model), label: 'See Used for Sale' }
          : {
              to: generateNewFlatUrl(params),
              label: 'See All for Sale',
            })}
      />
    </DockedContainer>
  );
}

DockedSrpLink.propTypes = {
  params: CorePageParams.isRequired,
  showElementSelector: PropTypes.string.isRequired,
  creativeId: PropTypes.string,
  trackingId: PropTypes.string,
  inventoryTotalNumber: PropTypes.number,
};

DockedSrpLink.defaultProps = {
  creativeId: null,
  trackingId: null,
  inventoryTotalNumber: null,
};
