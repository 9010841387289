import { flatten, get } from 'lodash';
import {
  DEALS_BG_COLORS_STYLE,
  DEALS_LARGE_TEXT_COLORS_STYLE,
  DEALS_SMALL_TEXT_COLORS_STYLE,
  FAIR_DEAL,
  GOOD_DEAL,
  GREAT_DEAL,
  HIGH_DEAL,
} from 'site-modules/shared/components/deal/deal-constants';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';

export const SUGGESTED_PRICE_TOOLTIP =
  'The Edmunds Suggested Price is what we recommend you pay, not including taxes and fees. It’s the Edmunds True Market Value, which is based on our analysis of recent purchases, and all generally available current incentives and rebates. Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.';
export const HIGH_ESP_TOOLTIP =
  'Low supply is pushing what others are paying above MSRP. This price is based on our analysis of recent purchases, and includes all generally available current incentives and rebates. It does not include taxes and fees. Edmunds pricing is a trusted industry benchmark for what others are paying for this vehicle in your area.';

export function getPriceData({ maxFairPrice, maxGoodPrice, maxGreatPrice, bestDealPrice, userDealerPrice, isHighEsp }) {
  const espLabel = isHighEsp ? 'What others are paying:' : 'Edmunds suggests you pay';
  const espTooltip = isHighEsp ? HIGH_ESP_TOOLTIP : SUGGESTED_PRICE_TOOLTIP;

  const currentUserDealerPrice = userDealerPrice || maxGoodPrice;
  if (currentUserDealerPrice <= maxGreatPrice) {
    return {
      label: GREAT_DEAL,
      index: 2,
      cta: 'You should take it!',
      color: DEALS_LARGE_TEXT_COLORS_STYLE[GREAT_DEAL],
      bgColor: DEALS_BG_COLORS_STYLE[GREAT_DEAL],
    };
  }
  if (currentUserDealerPrice <= maxGoodPrice) {
    return {
      label: GOOD_DEAL,
      index: 6,
      cta: 'You should take it!',
      color: DEALS_LARGE_TEXT_COLORS_STYLE[GOOD_DEAL],
      bgColor: DEALS_BG_COLORS_STYLE[GOOD_DEAL],
    };
  }
  if (currentUserDealerPrice <= maxFairPrice) {
    return {
      label: FAIR_DEAL,
      index: 12,
      cta: `${espLabel} ${formatPriceString(bestDealPrice || maxGoodPrice)}`,
      tooltip: espTooltip,
      color: DEALS_LARGE_TEXT_COLORS_STYLE[FAIR_DEAL],
      bgColor: DEALS_BG_COLORS_STYLE[FAIR_DEAL],
    };
  }

  return {
    label: HIGH_DEAL,
    index: 15,
    cta: `${espLabel} ${formatPriceString(bestDealPrice || maxGoodPrice)}`,
    tooltip: espTooltip,
    color: DEALS_LARGE_TEXT_COLORS_STYLE[HIGH_DEAL],
    bgColor: DEALS_BG_COLORS_STYLE[HIGH_DEAL],
  };
}

export function getEspBasedTmvChartData(ESP) {
  if (!ESP) {
    return null;
  }

  const priceMargin = ESP * 0.03;
  const maxFairPrice = parseFloat((ESP + priceMargin).toFixed(2));
  const maxGreatPrice = parseFloat((ESP - priceMargin).toFixed(2));

  return {
    maxFairPrice,
    maxGreatPrice,
    maxGoodPrice: ESP,
    dealerPrice: {
      ...getPriceData({ maxFairPrice, maxGoodPrice: ESP, maxGreatPrice, userDealerPrice: ESP }),
      index: 9.5, // Center of the graph
    },
  };
}

export function getDataPointsFromTransactionCount({
  transactionCount,
  transactionsCountAdjustments,
  maxValue,
  barsPerDeal,
}) {
  const { tier1, tier2, tier3, tier4 } = transactionCount;
  const countArray = [tier1, tier2, tier3, tier4];
  const countPerPoint = Math.max(...countArray) / maxValue;

  return flatten(
    countArray.map((count, tierIndex) =>
      new Array(barsPerDeal).fill().map((value, index) => {
        const dataPoint = count / countPerPoint;

        return parseFloat(
          Number(
            transactionsCountAdjustments
              ? dataPoint + dataPoint * transactionsCountAdjustments[tierIndex][index]
              : dataPoint
          ).toFixed(2)
        );
      })
    )
  );
}

export function getTransactionsCountOverlayConfig({ transactionCount, barsPerDeal, component, alwaysShow }) {
  const textProps = { textY: 20, textClassName: 'medium fw-medium' };

  return {
    isOverlapArrow: false,
    component,
    overlaysProps: [
      {
        alwaysShow,
        startPos: 0,
        endPos: barsPerDeal,
        colors: [
          {
            offset: '0%',
            stopColor: 'rgb(0, 126, 229)',
            stopOpacity: '0',
          },
          {
            offset: '100%',
            stopColor: 'rgb(0, 126, 229)',
            stopOpacity: '0.172',
          },
        ],
        ...textProps,
        text: `${get(transactionCount, 'tier1', 0)} sales`,
        textLabel: 'at a great price',
        textColor: DEALS_SMALL_TEXT_COLORS_STYLE[GREAT_DEAL],
      },
      {
        alwaysShow,
        startPos: barsPerDeal,
        endPos: barsPerDeal * 2,
        colors: [
          {
            offset: '0%',
            stopColor: 'rgb(0, 201, 227)',
            stopOpacity: '0',
          },
          {
            offset: '48%',
            stopColor: 'rgb(0, 176, 210)',
            stopOpacity: '0.092',
          },
          {
            offset: '100%',
            stopColor: 'rgb(0, 152, 194)',
            stopOpacity: '0.24',
          },
        ],
        ...textProps,
        text: `${get(transactionCount, 'tier2', 0)} sales`,
        textLabel: 'at a good price',
        textColor: DEALS_SMALL_TEXT_COLORS_STYLE[GOOD_DEAL],
      },
      {
        alwaysShow,
        startPos: barsPerDeal * 2,
        endPos: barsPerDeal * 3,
        colors: [
          {
            offset: '0%',
            stopColor: 'rgb(0, 201, 227)',
            stopOpacity: '0',
          },
          {
            offset: '48%',
            stopColor: 'rgb(0, 176, 210)',
            stopOpacity: '0.092',
          },
          {
            offset: '100%',
            stopColor: 'rgb(0, 194, 186)',
            stopOpacity: '0.24',
          },
        ],
        ...textProps,
        text: `${get(transactionCount, 'tier3', 0)} sales`,
        textLabel: 'at a fair price',
        textColor: DEALS_SMALL_TEXT_COLORS_STYLE[FAIR_DEAL],
      },
      {
        alwaysShow,
        startPos: barsPerDeal * 3,
        endPos: barsPerDeal * 4,
        colors: [
          {
            offset: '0%',
            stopColor: 'rgb(245, 148, 66)',
            stopOpacity: '0',
          },
          {
            offset: '50%',
            stopColor: 'rgb(245, 148, 66)',
            stopOpacity: '0.076',
          },
          {
            offset: '100%',
            stopColor: 'rgb(245, 148, 66)',
            stopOpacity: '0.2',
          },
        ],
        ...textProps,
        text: `${get(transactionCount, 'tier4', 0)} sales`,
        textLabel: 'at a high price',
        textColor: DEALS_SMALL_TEXT_COLORS_STYLE[HIGH_DEAL],
      },
    ],
  };
}
