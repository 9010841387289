import React from 'react';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';

export function NativeVideoMrect(props) {
  return (
    <NativeAd
      adNameWired="sponsoredvideo,mrect"
      adNameMobile="msponsoredvideo,mmrect"
      nativeStyle="sponsoredvideo,mediumrectangle"
      refreshable={false}
      renderWhenViewable
      verticalOffset="100%"
      customSizes={[[1, 1], [300, 250], 'fluid']}
      {...props}
    />
  );
}
