import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

// TODO: Remove after core-5931
export const AppointmentSurveyMap = {
  phev_survey_appointment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_ENGAGE_SURVEY,
    'phev_survey_appointment'
  ),
};
