import React from 'react';
import PropTypes from 'prop-types';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleModel, VehicleEntities, FeatureSpecsEntities, FeatureSpecsPaths } from 'client/data/models/vehicle-v2';
import { EditorialReviewModel, EditorialReviewEntities, buildReviewPath } from 'client/data/models/editorial-review';
import {
  ConsumerReviewsModel,
  ConsumerReviewsEntities,
  ConsumerReviewsPaths,
} from 'client/data/models/consumer-reviews';
import { VehicleDetailsModel, VehicleComparisonsEntities, buildWhatsNewPath } from 'client/data/models/mmy-details';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { getFaqEntries } from 'site-modules/core-page/utils/faq-content-generator';
import { SeoFaq } from 'site-modules/shared/components/faq/seo-faq';
import { getMmyTaxCreditAmountPath, IncentiveModel } from 'client/data/models/incentives';

export function SeoFaqSectionUI({
  vehicle,
  editorialReview,
  consumerReviews,
  ratingsCount,
  stylesList,
  whatsNewContent,
  taxRebateAmount,
  params,
}) {
  const entries = getFaqEntries({
    vehicle,
    editorialReview,
    consumerReviews,
    ratingsCount,
    stylesList,
    whatsNewContent,
    taxRebateAmount,
  });

  return (
    <SeoFaq
      title="FAQ"
      titleComponent="h2"
      questionComponent="h3"
      entries={entries}
      pathname={getCoreUrl({
        makeSlug: params.make,
        modelSlug: params.model,
      })}
    />
  );
}

SeoFaqSectionUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  params: CorePageParams.isRequired,
  editorialReview: EditorialReviewEntities.EditorialReview,
  stylesList: PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity),
  whatsNewContent: VehicleComparisonsEntities.WhatsNewContent,
  consumerReviews: ConsumerReviewsEntities.ConsumerReviewsContent,
  ratingsCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  taxRebateAmount: PropTypes.number,
};

SeoFaqSectionUI.defaultProps = {
  stylesList: [],
  editorialReview: null,
  whatsNewContent: [],
  consumerReviews: null,
  ratingsCount: null,
  taxRebateAmount: null,
};

export const SeoFaqSection = connectToModel(SeoFaqSectionUI, {
  stylesList: bindToPath(
    ({ vehicle }) =>
      FeatureSpecsPaths.buildPartialFeaturesPath({
        make: vehicle.make.slug,
        model: vehicle.model.slug,
        submodel: vehicle.submodels.slug,
        year: vehicle.year,
      }),
    VehicleModel
  ),
  editorialReview: bindToPath(({ params }) => buildReviewPath(params), EditorialReviewModel),
  consumerReviews: bindToPath(ConsumerReviewsPaths.getConsumerReviewsPath(), ConsumerReviewsModel),
  ratingsCount: bindToPath(
    ({ params }) => ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPathFromParams(params),
    ConsumerReviewsModel
  ),
  whatsNewContent: bindToPath(({ params }) => buildWhatsNewPath(params), VehicleDetailsModel),
  taxRebateAmount: bindToPath(
    ({ vehicle, params: { isElectric } }) =>
      isElectric &&
      getMmyTaxCreditAmountPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
    IncentiveModel
  ),
});

SeoFaqSection.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
};
