import { LEGACY_PAGE_UPDATE } from './constants';

/**
 * LEGACY_PAGE_UPDATE action definition
 *
 * @param  {Object} legacyPageInfo  legacy page info from page preload
 * @return {Object}       LEGACY_PAGE_UPDATE action definition
 */
export function legacyPageUpdate(legacyPageInfo) {
  return {
    type: LEGACY_PAGE_UPDATE,
    legacyPageInfo,
  };
}
