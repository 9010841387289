import React from 'react';

export const CORE_PAGE_HEADER = {
  true: className => (
    <svg
      width={375}
      height={576}
      viewBox="0 0 375 576"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask id="a" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x={-1} y={0} width={376} height={576}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M375 562.386V0H0v537.718C59.52 559.278 149.7 576 270 576c35.844 0 70.648-5.432 105-13.614z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M826 512c116 45.015 222 64 304 75V-1H-70v501c40 36.024 154 76 340 76s344-146.269 556-64z"
          fill="url(#b)"
        />
        <path
          d="M511.718 61.893c42.993 1.53 86.281 5.888 129.704 13.204 311.829 52.485 552.538 242.016 657.698 483.656-149.97-147.971-450.891-226.33-789.375-232.079-338.534.839-640.434 74.572-792.746 220.292 108.75-240.047 352.255-425.97 664.832-473.803a1041.548 1041.548 0 0 1 129.887-11.27z"
          fill="url(#c)"
          fillOpacity=".1"
        />
        <path
          d="M541.247 208.93a1458.84 1458.84 0 0 1 139.573 27.692c333.61 83.239 577.16 267.208 668.67 475.188-148.93-138.63-468.279-241.218-835.209-290.421-367.6-43.915-702.265-24.445-881.281 72.561 140.567-178.524 422.355-295.817 766.181-293.074 47.867.484 95.274 3.212 142.066 8.054z"
          fill="url(#d)"
          fillOpacity=".5"
        />
        <path
          opacity=".3"
          d="M665.039 878.394c48.524-6.62 97.023-16.967 145.3-31.2C1157.03 745.059 1405.76 471.327 1494.28 144c-151.17 209.633-482.23 344.436-865.05 388.31-383.714 35.261-735.16-28.746-926.229-202.968 153.667 302.268 453.168 519.275 813.428 548.262 50.15 3.871 99.74 4.07 148.61.79z"
          fill="url(#e)"
        />
        <path
          opacity=".9"
          d="M624.222 782.712c50.956 12 103.317 20.27 156.946 24.578 385.102 31.013 738.232-146.76 952.062-439.434-231.22 151.216-613.24 158.795-1012.047 55.796C324.784 311.716-1.357 113.101-125.211-134c37.44 360.528 253.087 691.895 601.449 858.946 48.557 23.091 97.963 42.302 147.984 57.766z"
          fill="url(#f)"
        />
        <path
          d="M826 512c116 45.015 222 64 304 75V-1H-70v501c40 36.024 154 76 340 76s344-146.269 556-64z"
          fill="url(#g)"
          fillOpacity=".3"
        />
      </g>
      <defs>
        <linearGradient id="b" x1={935} y1={22} x2={919.087} y2={551.522} gradientUnits="userSpaceOnUse">
          <stop stopColor="#007EE5" />
          <stop offset=".334" stopColor="#1561D4" />
        </linearGradient>
        <linearGradient id="c" x1={605.999} y1={647.999} x2={809.999} y2={119.999} gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="d" x1={586.5} y1={212} x2={567.602} y2={621.259} gradientUnits="userSpaceOnUse">
          <stop offset=".115" stopColor="#fff" stopOpacity={0} />
          <stop offset=".944" stopColor="#017D8D" />
        </linearGradient>
        <linearGradient id="e" x1={328} y1={598} x2={667} y2={386} gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ABB5" />
          <stop offset={1} stopColor="#00A0B5" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="f" x1={981.5} y1={566} x2={201} y2={574} gradientUnits="userSpaceOnUse">
          <stop stopColor="#0098C2" />
          <stop offset={1} stopColor="#033E96" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="g" x1={935} y1={22} x2={916.038} y2={586.43} gradientUnits="userSpaceOnUse">
          <stop offset=".813" stopOpacity={0} />
          <stop offset=".972" />
        </linearGradient>
      </defs>
    </svg>
  ),
  false: className => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1200}
      height={588}
      viewBox="0 0 1200 588"
      fill="none"
      preserveAspectRatio="none"
      className={className}
    >
      <mask id="b" width={1200} height={588} x={0} y={0} maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
        <path
          fill="url(#a)"
          d="M896 513c116 45.015 222 64 304 75V0H0v501c40 36.024 154 76 340 76s344-146.269 556-64Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="url(#c)"
          d="M896 513c116 45.015 222 64 304 75V0H0v501c40 36.024 154 76 340 76s344-146.269 556-64Z"
        />
        <path
          fill="url(#d)"
          fillOpacity=".15"
          d="M581.718 62.893c42.993 1.53 86.281 5.888 129.704 13.204 311.828 52.485 552.538 242.016 657.698 483.656-149.97-147.971-450.891-226.33-789.375-232.079-338.534.839-640.434 74.572-792.746 220.292 108.75-240.047 352.255-425.97 664.832-473.803a1041.548 1041.548 0 0 1 129.887-11.27Z"
        />
        <path
          fill="url(#e)"
          fillOpacity=".5"
          d="M611.247 209.93a1458.84 1458.84 0 0 1 139.573 27.692c333.61 83.239 577.16 267.208 668.67 475.188-148.93-138.63-468.279-241.218-835.209-290.421-367.6-43.915-702.265-24.445-881.281 72.561 140.567-178.524 422.355-295.817 766.181-293.074 47.867.484 95.274 3.212 142.066 8.054Z"
        />
        <path
          fill="url(#f)"
          d="M735.039 879.394c48.524-6.62 97.023-16.967 145.3-31.2C1227.03 746.059 1475.76 472.327 1564.28 145c-151.17 209.633-482.23 344.436-865.05 388.31C315.515 568.571-35.93 504.564-227 330.342-73.333 632.61 226.169 849.617 586.429 878.604c50.15 3.871 99.74 4.07 148.61.79Z"
          opacity=".3"
        />
        <path
          fill="url(#g)"
          d="M694.222 783.712c50.956 12 103.317 20.27 156.946 24.578 385.102 31.013 738.232-146.76 952.062-439.434-231.22 151.216-613.24 158.795-1012.047 55.796C394.784 312.716 68.643 114.101-55.211-133c37.44 360.528 253.087 691.895 601.449 858.946 48.557 23.091 97.963 42.302 147.984 57.766Z"
          opacity=".9"
        />
        <path
          fill="url(#h)"
          fillOpacity=".5"
          d="M896 513c116 45.015 222 64 304 75V0H0v501c40 36.024 154 76 340 76s344-146.269 556-64Z"
        />
      </g>
      <defs>
        <linearGradient id="a" x1={1005} x2={989.087} y1={23} y2={552.522} gradientUnits="userSpaceOnUse">
          <stop stopColor="#007EE5" />
          <stop offset=".333" stopColor="#1561D4" />
          <stop offset={1} stopColor="#0A4AAB" />
        </linearGradient>
        <linearGradient id="c" x1={1005} x2={989.087} y1={23} y2={552.522} gradientUnits="userSpaceOnUse">
          <stop stopColor="#007EE5" />
          <stop offset=".334" stopColor="#1561D4" />
        </linearGradient>
        <linearGradient id="d" x1={675.999} x2={879.999} y1={648.999} y2={120.999} gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="e" x1={656.5} x2={637.602} y1={213} y2={622.259} gradientUnits="userSpaceOnUse">
          <stop offset=".115" stopColor="#fff" stopOpacity={0} />
          <stop offset=".944" stopColor="#017D8D" />
        </linearGradient>
        <linearGradient id="f" x1={398} x2={737} y1={599} y2={387} gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ABB5" />
          <stop offset={1} stopColor="#00A0B5" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="g" x1={1051.5} x2={271} y1={567} y2={575} gradientUnits="userSpaceOnUse">
          <stop stopColor="#0098C2" />
          <stop offset={1} stopColor="#033E96" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="h" x1={1005} x2={986.038} y1={23} y2={587.43} gradientUnits="userSpaceOnUse">
          <stop offset=".813" stopOpacity={0} />
          <stop offset=".972" />
        </linearGradient>
      </defs>
    </svg>
  ),
};
