import { objectToQueryString } from './string';

const PRICE_CHECKER_PARAMS = ['make', 'model', 'year', 'styleId', 'msrp'];

export function getPriceCheckerLink({ make, model, year, styleId, msrp }, additionalQuery = null) {
  const queryObj = { make, model, year, styleId, msrp };

  // Each param requires all others which stand before.
  PRICE_CHECKER_PARAMS.forEach((param, index) => {
    queryObj[param] = PRICE_CHECKER_PARAMS.slice(0, index).every(restParam => queryObj[restParam])
      ? queryObj[param]
      : null;
  });
  const query = objectToQueryString({ ...queryObj, ...additionalQuery });

  return `/price-checker/${query ? `?${query}` : ''}`;
}

export function getModelPriceCheckerLink({ make, model, year }, additionalQuery = null) {
  if (!(make && model && year)) {
    return getPriceCheckerLink({}, additionalQuery);
  }

  const query = objectToQueryString({ ...additionalQuery });

  return `/${make}/${model}/${year}/msrp/${query ? `?${query}` : ''}`;
}
